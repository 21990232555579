.patients-tab {
  row-gap: 20px;
}

.recent-document {
  .client-table {
    .ant-table {
      .ant-table-tbody {
        max-height: calc(100vh - 500px);
        overflow-y: auto;
        display: block;
        word-break: break-all;
      }
    }
    .ant-table-container table {
      .ant-table-cell {
        &:nth-child(2),
        &:nth-child(3) {
          width: 165px;
        }
        &:last-child {
          text-align: center;
          width: 150px;
        }
      }
    }
    .status {
      border: 1px solid #f5f9ff;
      box-shadow: 0px 2px 15px rgb(0 0 0 / 5%);
      border-radius: 3px;
      display: inline-block;
      padding: 6px 10px;
      font-size: 12px;
      font-family: Font-Medium;
    }
  }
}

.matched-patient {
  .basic-detail-content {
    margin: 20px 0 25px 0;
  }
  .ant-table {
    .ant-table-tbody {
      max-height: calc(100vh - 338px);
    }
  }
}

@import "../../assets/sass/variables";
.cohort-drawer-container {
  display: grid;
  grid-template-columns: 80% 20%;
  transition: all .7s;
  &.sidebar-collapse {
    grid-template-columns: 50% 50%;
  }
  &.sidebar-collapse-full {
    grid-template-columns: 0 100%;
    .basic-detail-content{
      padding: 0;
    }
  }
}
.cohort-drawer {
  // width: 600px;
  height: calc(100vh - 208px);
  padding: 20px;
  border-right: 1px solid $borderColor;
  overflow-y: auto;
  &-title {
    margin-bottom: 8px;
    color: $purple-text;
    font-family: Font-Semibold;
  }
  // &-bottom {
  //   width: 100%;
  //   background: $baseColor;
  //   position: absolute;
  //   padding: 20px 40px 20px;
  //   bottom: 0px;
  //   right: 0px;
  //   border-right: 1px solid $borderColor;
  //   box-shadow: 20px 100px 50px rgba(0, 0, 0, 0.06);
  // }
  .extract-search {
    height: 38px;
  }

  .accordian {
    &.ant-collapse {
      background-color: $baseColor;
      border: 0px;
      .ant-collapse-item {
        // background-color:$borderColor;
        border: 0px;
      }
    }
  }
}

.cohort-max-height {
  height: calc(100vh - 82px) !important;
}

.overlap-icon {
    align-items: center;
    background: #fff;
    border: 1px solid #e7ecf4;
    border-radius: 0 30px 30px 0;
    border-left: 0;
    display: flex;
    height: 100px;
    justify-content: center;
    left: 100%;
    position: absolute;
    top: 50%;
    width: 30px;
    flex-direction: column;
    gap: 5px;
  svg{
    align-self: center;
    height: 15px;
    padding-top: 3px;
    width: 12px;
  }
}

.cohort-container {
  padding: 20px 20px 20px 40px;
  overflow-y: auto;
  height: calc(100vh - 208px);
  &.drawer-collapsed {
    border-left: 1px solid $borderColor;
  }
}

.lh-2 {
  line-height: 2.2;
}

.icon-row {
  .ant-table-container table {
    .ant-table-cell:last-child {
      width: 50px;
    }
  }
}

.criteria-categories-table {
  .ant-table {
    border: 1px solid $borderColor;
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      // border-inline-end: 1px solid $borderColor;
      text-align: center;
      line-height: 2.2;
    }
    .ant-table-container {
      table {
        .ant-table-cell:first-child {
          width: 130px;
        }
        .ant-table-cell:nth-child(3),
        .ant-table-cell:nth-child(4) {
          width: 160px;
        }
      }
    }
    .ant-table-tbody {
      word-break: break-word;
    }
  }
}
.medical-term {
  background: $baseTextColor;
  color: $baseColor;
  padding: 5px;
  border-radius: 4px;
}

.dropdown-export.ai-select {
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: $bgBtn;
    color: $baseColor;
    height: 36px;
    min-width: 160px;
    box-shadow: none;
    font-family: Font-Semibold;
    border-radius: 6px;
  }
  &.ant-select-disabled {
    opacity: 0.5;
  }

  .ant-select-arrow {
    color: $baseColor;
  }
  &.ant-select-open {
    .ant-select-selection-item {
      color: $baseColor;
    }
  }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    line-height: 34px;
  }
  &.ant-select:hover .ant-select-clear {
    opacity: 0;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 34px;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $linkColor;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: $linkColor;
    box-shadow: none;
  }
}

@use "../../assets/sass/variables";
.attachment {
  .ant-upload.ant-upload-drag {
    background: variables.$baseColor;
    border: 1px dashed variables.$CardBorderColor;
    border-radius: 4px;
    flex-basis: 50%;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  }

  .ant-upload.ant-upload-drag:hover,
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: variables.$linkColor;
  }

  &-Content {
    padding: 20px 30px 30px;

    svg {
      font-size: 52px;
      fill: variables.$baseTextColor;
    }
  }

  p {
    // font-size: 14px;
    color: variables.$baseTextColor;
    span {
      font-family: Font-Semibold;
      color: variables.$linkColor;
    }
  }

  h3 {
    text-align: left;
  }

  // .uploadButton {
  //   margin-top: 20px;
  // }

  // .aiButton {
  //   max-width: 100px;
  //   min-width: 100px;
  //   max-width: 100px;
  // }

  // -------file-uploader-----------

  > span {
    display: flex;
    gap: 20px;

    .ant-upload-list {
      flex-basis: 50%;
      border-radius: 4px;
      padding: 16px;
      overflow-y: auto;
      max-height: 200px;
      border: 1px solid variables.$CardBorderColor;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);

      &-item {
        height: 100%;
        margin-top: 0px;

        &-info {
          padding: 5px 0;

          .ant-upload-text-icon .anticon,
          .anticon-loading .anticon {
            color: variables.$baseTextColor;
          }
        }
      }
      &-text {
        .ant-upload-list-item-name {
          color: variables.$baseTextColor;
        }
      }
    }
    .ant-upload-list-item-card-actions .anticon {
      color: variables.$baseTextColor;
    }
  }
}

.annotation-upload {
  .ant-upload.ant-upload-drag {
    min-height: 400px;
    display: flex;
    align-items: center;
  }
}

@import "../../assets/sass/variables";
.eligibility-checker-dashboard {
  padding: 15px 20px;

  .first-project {
    // &-img {
    //   flex-basis: 35%;
    //   text-align: center;
    // }

    // .autonomizeModal {
    //   flex-basis: 65%;
    // }
    &-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;

      .ant-form-item:last-child {
        padding-top: 0px;
      }
    }
  }
}

.grid-View {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.project-card-view {
  .project-name {
    font-size: 14px;
    font-family: Font-Semibold;
    color: $linkColor;
  }
  .editDelete {
    svg,path{
      fill: $linkColor;
    }
  }
  p {
    font-size: 12px;
  }
  .ql-editor {
    padding: 12px 0px;
  }
  .highlight {
    font-family: Font-Semibold;
  }
}

.view-control {
  opacity: 0.2;
  &.active {
    opacity: 1;
    color: $linkColor;
  }
}

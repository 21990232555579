.user-table {
  margin-top: 20px;
  border: 1px solid #f5f9ff;
  border-radius: 4px;
  box-shadow: 0 2px 15px rgb(0 0 0 / 5%);
  .ant-table {
    .ant-table-tbody {
      max-height: calc(100vh - 250px);
    }
  }
}
.ant-table-expanded-row {
  .ant-layout-content {
    max-height: 250px;
  }
}

.mark-text {
  color: #111;
  margin: 5px;
  border-radius: 4px;
  padding: 5px !important;
  span {
    font-family: Font-Bold;
    padding: 3px;
    margin: 0 5px !important;
    border-radius: 4px;
    color: #fff;
  }
}

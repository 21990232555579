@import "../../assets/sass/variables";

.user-page {
  height: 100vh;
  background: $bgGradient;
  .brand-title {
    color: $baseColor;
    font-family: Damion,cursive;
    font-size: 48px;
    line-height: 1;
  }
  a {
    font-family: Font-Medium;
    color:rgba($color: $baseColor, $alpha: 0.8);
    &:hover{
      color: $baseColor;
    }
  }

  &-container {
    max-width: 380px;
    width: 100%;
    gap: 10px;
  }
  .login {
    &-title {
      font-size: 30px;
      color: $tabdetail;
      font-family: Font-Semibold;
      margin: 20px 0 30px 0;
      line-height: 1.3;
    }
  }
  .autonomizeModal {
    .ant-form-item {
      margin-bottom: 40px;
    }
  }
}
.login-content-modal {
  padding: 30px 90px;
}
.email-confirmation {
  svg {
    width: 53px;
    height: 45px;
  }
  h3 {
    margin-top: 20px;    gap: 20px;
    color: $tabdetail;
    font-family: Font-Semibold;
  }
}

.user-form {
  &.autonomizeModal {
    .ant-input, .ant-input-affix-wrapper {
      background: rgba($color: $baseColor, $alpha: 0.2);
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      border: 0px;
      color: $baseColor;
      padding:10px 14px;
    }

    .ant-input-affix-wrapper {
      .ant-input{
        padding:0;
        background: rgba($color: $baseColor, $alpha: 0);
      }
    }

    .ant-input-password-icon.anticon{
      color: $baseColor;
    }

    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, 
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, 
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
      background: rgba($color: $baseColor, $alpha: 0.2);
      color: $baseColor;
    }
    .login-button{
      height: 36px;
      padding: 0 20px;
      background: transparent;
      border: 1px solid $baseColor;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: $baseColor;
      font-size: 12px;
      font-family: Font-medium;

      &:hover{
        background: $purple;
        border-color: $purple;
      }
      &[disabled]{
        color: $baseColor;
      }
    }
  }
  h2 {
    font-family: Font-Semibold;
    margin: 0px 0 20px;
    color: $baseColor;
  }
  h4,p{
    color: rgba($color: $baseColor, $alpha: 0.8);
  }
  &-message {
    padding-bottom: 30px;
  }
  .ant-input-affix-wrapper {
    .ant-input {
      margin-top: 0px;
      box-shadow: 0px 0px 0px;
    }
    // &:hover {
    //   border-color: $linkColor;
    // }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $linkColor;
    box-shadow: 0 0 0 2px rgb(59 112 205 / 20%);
  }
}
.brand-logo{
  align-items: baseline;
  justify-content: flex-start;
  gap: 20px;
  &-img {
    height: 48px;
  }
}

.brand-title{
  color: $baseColor;
  font-size: 48px;
  font-family: 'Damion', cursive;
  line-height: 1;
  &-highlight{
    color:#E8AE55;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

@use "../../../assets/sass/variables";

.disable-link {
  pointer-events: none;
}

.sidebar {
  .logo {
    margin-bottom: 40px;
  }
  &.ant-layout-sider {
    padding: 25px;
    background: variables.$bgGradient;
    height: 100%;
  }

  &-nav {

    &-link {
      margin: 30px 0;
      position: relative;
      color: rgba($color: variables.$baseColor, $alpha: 0.6);

      &.active,
      &:hover {
        color: variables.$baseColor;
      }
    }
  }
  svg {
    width: 16px;
    height: 16px;
  }
  
  .ant-layout-sider-trigger {
    margin-bottom: 10px;
    width: auto !important;
    background: transparent;
  }
}

.rotate-icon {
  transform: rotate(180deg);
}

// .back {
//   background: rgba(232, 174, 85, 0.12);
//   padding: 16px 20px;
//   border-radius: 0 30px 30px 0;
//   margin-right: 35px;
//   gap: 10px;
//   cursor: pointer;
//   font-size: 14px;

//   svg {
//     align-self: center;
//     display: flex;
//   }
// }

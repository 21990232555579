@use "./variables";
.ant-card {
  color: $baseTextColor;
  // background: var(--bgcolor, $cardBgColor);
  border: var(--borderwidth, 1px) solid $CardBorderColor;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);

  .listitem {
    padding: 10px;
    padding-block: 8px;
    // font-weight: 400;
    font-size: 12px;
    color: variables.$baseTextColor;
    margin-inline: calc(var(--fullspacing, 0) * -1);
    border-bottom: 1px solid $CardBorderColor;

    // padding-inline: var(--fullspacing, 0);

    &:not(:last-child) {
      border-bottom: 1px solid $CardBorderColor;
    }
    &:nth-child(even) {
      background: var(--fullspacingBg);
    }
    &:hover {
      background: $selectRowColor;
    }
  }
  .ant-space {
    display: flex;
  }
  .ant-card-body {
    padding: 1px;
    border-radius: 10px;
  }
}
